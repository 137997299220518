import React from 'react';
import ShapeWrapper from './tiltShape.style';

const TiltShape = ({
   className, color, topOrBottom
}) => {
  // Add all classs to an array
  const addAllClasses = ['tilt_shape'];
  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  var bottomSvg = <svg width="1440" height="249" viewBox="0 0 1440 249" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0V249H1440V137.111C1412.24 148.752 1379.07 157.535 1345.92 161.009L670.059 231.863C598.4 239.374 495.96 225.317 441.215 200.446L0 0Z" fill={color} />
  </svg>;

  var topSvg = <svg width="1440" height="254" viewBox="0 0 1440 254" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1440 254V0H0V114.136C27.761 102.261 60.9346 93.3016 94.0844 89.7581L769.941 17.4814C841.6 9.81964 944.04 24.1587 998.785 49.5293L1440 254Z" fill={color} />
  </svg>;

  var wrapper = <ShapeWrapper className={addAllClasses.join(' ')} topOrBottom={topOrBottom}>
    {(topOrBottom === "top") ? topSvg : bottomSvg}
  </ShapeWrapper>;
  
  return wrapper;
};

export default TiltShape;

TiltShape.defaultProps = {
  color: "#FFF",
  topOrBottom: "top"
};