import styled from 'styled-components';

const SectionWrapper = styled.section`
  padding: 150px 0 250px 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.18) 100%), #F2F2F2;

  @media (max-width: 990px) {
    padding: 150px 0 150px 0;
  }

  @media (max-width: 460px) {
    .products-container {
      padding-left: 0;
      padding-right: 0;
    }
    
  }

`;

export default SectionWrapper;
