import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Container from 'common/src/components/UI/Container';
import Heading from 'common/src/components/Heading';
import LinkButton from '../LinkButton';

import ContactSectionWrapper from './contact.style';

const ContactSection = ({
  row,
  col,
  description,
  highlight,
  textArea,
  rightArea,
  heading
}) => {
  return (
    <ContactSectionWrapper id="contact">
      <Container>
        <Box {...row}>
          <Box {...col} {...textArea} pr="100px">
            <Heading as="h3" content="Get in contact" {...heading}/>
            <Text
              {...description}
              content="Questions about our products or us? Just write a mail, we look forward hearing from you!"
            />
            <LinkButton title="hello@addcraft.io" href="mailto:hello@addcraft.io" />
          </Box>
          <Box {...col} {...textArea} {...rightArea} className="right-column">
            <Heading as="h3" content="Stay up to date" {...heading}/>
            <Text
              {...description}
              content="Register for the addcraft Newsletter and stay up to date about our products!"
            />
            <LinkButton title="Get our newsletter" href="https://share.hsforms.com/1t7rMfprCRSiS_Vc4-laIOg51s11" target="_blank" />
          </Box>
        </Box>
        
      </Container>
    </ContactSectionWrapper>
  );
};

ContactSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  description: PropTypes.object,
  textArea: PropTypes.object,
  rightArea: PropTypes.object,
  heading: PropTypes.object
};

ContactSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap'
  },
  col: {
    flexGrow: 1,
    flexShrink: 1,
    pt: "50px",
    pb: "50px",
  },
  textArea: {
    width: ['100%', '100%', '100%', '50%', '50%'],
  },
  rightArea: {
    pl: ['0','0','0','100px','100px']
  },
  heading: {
    fontSize: '36px',
    fontWeight: '600',
    color: '#005688',
    mb: '30px'
  },
  description: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    color: '#000',
    lineHeight: '1.5',
    mb: '33px',
  }
};

export default ContactSection;
