import styled from 'styled-components';

const ShapeWrapper = styled.div`
  position: absolute;
  width: 100%;

  ${props => props.topOrBottom === "bottom" && `
    bottom: -1px;
  `}

  ${props => props.topOrBottom === "top" && `
    top: -1px;
  `}

  left: 0;
  z-index: 0;
  pointer-events: none;

  svg {
    width: 100%;
    height: auto;
    display: block;
  }
`;

export default ShapeWrapper;
