import styled from 'styled-components';

export const HeroWrapper = styled.section`
  padding: 150px 0 270px 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.29) 100%), #005688;

  @media (max-width: 990px) {
    padding: 150px 0 100px 0;
  }

  @media (max-width: 500px) {
    padding: 80px 0 100px 0;
  }

  @media (max-height: 768px) {
    padding: 50px 0 150px 0;
  }

  @media (max-width: 400px) {
    .header-illustration {
      display: none;
    }
  }
`;
