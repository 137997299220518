import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import GImage from 'gatsby-image';

const CustomersSection = ({
  sectionWrapper,
  secTitleWrapper,
  secHeading,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      customersImage: file(
        relativePath: { eq: "image/addcraft/logos-grayscale.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Box {...sectionWrapper}>
      <Container>
        <Box {...secTitleWrapper}>
          <Heading {...secHeading} content="Trusted by great customers" />
        </Box>

        <Box>
          <GImage
            fluid={Data.customersImage.childImageSharp.fluid}
            alt="*"
          />
        </Box>
      </Container>
    </Box>
  );
};

CustomersSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
};

CustomersSection.defaultProps = {
  sectionWrapper: {
    pt: '50px',
    pb: '50px',
    backgroundColor: "#FFF"
  },
  secTitleWrapper: {
    mb: '20px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: '36px',
    fontWeight: '600',
    color: '#005688',
    mb: '0'
  },
};

export default CustomersSection;
