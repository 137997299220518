import styled from 'styled-components';

const FooterWrapper = styled.footer`
  position: relative;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.29) 100%), #005688;
  
  overflow: hidden;
  @media (min-width: 576px) {
    padding-top: 80px;
  }

  .footer_container {
    padding-top: 80px;
    padding-bottom: 20px;
    position: relative;

    @media (max-width: 990px) {
      padding-bottom: 20px;
    }
    @media (max-width: 767px) {
      padding-bottom: 0px;
    }
  }
`;

const List = styled.ul``;

const ListItem = styled.li`
  a {
    display: block;
    color: #FFF;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 8px;
    transition: all 0.2s ease;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: underline;
    }
  }
`;

export { List, ListItem };

export default FooterWrapper;
