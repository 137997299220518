import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GImage from 'gatsby-image';

import Text from 'common/src/components/Text';
import { useStaticQuery, graphql } from 'gatsby';

const BulletWrapper = styled.div`
  clear: left;
  padding-bottom: 16px;
  font-weight: 500;
  color: #005688;
`;

const ImageWrapper = styled.div`
  float: left;
  padding-right: 10px;
  position: relative;
  top: -2px;
`;

const Bulletpoint = ({
  content
}) => {

  const Data = useStaticQuery(graphql`
    query {
      tickImage: file(
        relativePath: { eq: "image/addcraft/addcraft-checkbox.png" }
      ) {
        childImageSharp {
          fixed(width: 24, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <BulletWrapper>
      <ImageWrapper>
        <GImage
          fixed={Data.tickImage.childImageSharp.fixed}
          alt="*"
        />
      </ImageWrapper>
      <Text
        content={content}
      />
    </BulletWrapper>
  );
};

Bulletpoint.propTypes = {
  content: PropTypes.string
};

export default Bulletpoint;
