import styled from 'styled-components';

const InfoSectionWrapper = styled.section`
  padding: 80px 0 240px 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.18) 100%), #F2F2F2;

  @media (max-width: 768px) {
    padding: 80px 0 120px 0;
  }
`;

export default InfoSectionWrapper;

/*
  @media (max-width: 1600px) {
    padding: 80px 0 200px 0;
  }
  @media (max-width: 1400px) {
    padding: 80px 0 180px 0;
  }
  @media (max-width: 1300px) {
    padding: 80px 0 150px 0;
  }
  @media (max-width: 1199px) {
    padding: 80px 0 100px 0;
  }
  @media (max-width: 767px) {
    padding: 80px 0 60px 0;
  }
*/