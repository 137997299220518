import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import GImage from 'gatsby-image';

import Text from 'common/src/components/Text';

const TileWrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  width: 50%;
  margin-bottom: 40px;

  cursor: ${props => (props.href ? "pointer" : "default")};

  @media (max-width: 990px) {
    width: 100%;
  }
`;

const TileInner = styled.a`
  display: block;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
  background: #FFF;
  border-radius: 16px;
  margin: 0 20px;
  overflow: hidden;

  transition: all 0.2s ease;

  &:hover {
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.45));
  }
`;

const ContentInner = styled.span`
  display: block;
  padding: 50px;
  min-height: 202px;

  @media (max-width: 400px) {
    padding: 30px 10px;
  }
`;

const Tile = ({
  imageData,
  title,
  details,
  headerStyle,
  detailStyle,
  href,
  target
}) => {
  
  return (
    <TileWrapper>
      <TileInner href={href ? href : null} target={target}>
        <GImage fluid={imageData.childImageSharp.fluid} alt={title} />
        <ContentInner>
          <Text as="span"
              {...headerStyle}
              content={title}
          />
          <Text as="span"
              {...detailStyle}
              content={details}
          />
        </ContentInner>
      </TileInner>
    </TileWrapper>
  );
};

Tile.propTypes = {
  imageData: PropTypes.object.isRequired,
  title: PropTypes.string,
  details: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  headerStyle: PropTypes.object,
  detailStyle: PropTypes.object
};

Tile.defaultProps = {
  title: "Presenter for Confluence",
  details: "Say goodbye to transfering your content into Powerpoint presentations. Present any Confluence page in your browser.",
  target: "_blank",
  headerStyle: {
    display: "block",
    fontFamily: "Montserrat Alternates",
    fontWeight: "600",
    fontSize: "24px",
    color: "#005688"
  },
  detailStyle: {
    color: "#000",
    lineHeight: '1.3'
  },

};

export default Tile;
