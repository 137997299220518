import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Container from 'common/src/components/UI/Container';
import TiltShape from '../TiltShape';

import Bulletpoint from '../Bulletpoint';

import InfoSectionWrapper from './info.style';

const InfoSection = ({
  row,
  col,
  description,
  highlight,
  textArea,
  bulletArea
}) => {
  return (
    <InfoSectionWrapper>
      <TiltShape topOrBottom="bottom" />
      <Container>
        <Box {...row}>
          <Box {...col} {...textArea}>
            <Text
              {...description}
              content="addcraft creates software products that supercharge the value you get from the Atlassian ecosystem. We sell our award-winning apps on the Atlassian marketplace."
            />
            <Text
              {...description} {...highlight}
              content="Our goal: making our customers happy by improving the software tools they use daily."
            />
          </Box>
          <Box {...col} {...textArea} {...bulletArea}>
            <Bulletpoint content="Apps built with love and balance" />
            <Bulletpoint content="GDPR / DSGVO compliant" />
            <Bulletpoint content="Built and hosted in Germany" />
            <Bulletpoint content="Great support directly by developers" />
          </Box>
        </Box>
        
      </Container>
    </InfoSectionWrapper>
  );
};

InfoSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  description: PropTypes.object,
  textArea: PropTypes.object,
  bulletArea: PropTypes.object
};

InfoSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    pr: '15px',
    pl: '15px',
    flexGrow: 1,
    flexShrink: 1
  },
  textArea: {
    width: ['100%', '100%', '50%', '50%', '50%'],
  },
  bulletArea: {
    pl: ['15px','15px','60px','120px','120px'],
    pt: '8px'
  }, 
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '2',
    mb: '33px',
  },
  highlight: {
    fontWeight: '500',
    color: '#005688'
  },
};

export default InfoSection;
