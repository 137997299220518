import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';

import { addcraftTheme } from 'common/src/theme/addcraft';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/addcraft/addcraft.style';

import CookieBanner from '../containers/addcraft/CookieBanner';
import Navbar from '../containers/addcraft/Navbar';
import HeroSection from '../containers/addcraft/Hero';
import InfoSection from '../containers/addcraft/Info';
import CustomersSection from '../containers/addcraft/Customers'; 
import ProductsSection from '../containers/addcraft/Products';
import ContactSection from '../containers/addcraft/Contact';
import Footer from '../containers/addcraft/Footer';

import "fontsource-montserrat-alternates/400.css";
import "fontsource-montserrat-alternates/500.css";
import "fontsource-montserrat-alternates/600.css";
import "fontsource-roboto/400.css";
import "fontsource-roboto/500.css";

export default () => {
  return (
    <ThemeProvider theme={addcraftTheme}>
      <Fragment>
        <SEO
          title="addcraft - the Atlassian app makers"
          description="addcrafts mission is to create the missing pieces for the tools you use daily."
        />

        <ResetCSS />
        <GlobalStyle />

        <CookieBanner />

        <ContentWrapper>
          <DrawerProvider>
            <Navbar />
          </DrawerProvider>
          <HeroSection />

          <InfoSection />
          <CustomersSection />
          <ProductsSection />
          <ContactSection />

          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
