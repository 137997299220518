import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import GImage from 'gatsby-image';

import PropTypes from 'prop-types';

import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import TiltShape from '../TiltShape';
import { HeroWrapper } from './hero.style';
import HeroIllustration from './hero.illustration';

const HeroSection = ({
  row,
  contentCol,
  imageCol,
  title,
  description,
  badgeWrapperWide,
  badgeWrapperSmall
}) => {
  const Data = useStaticQuery(graphql`
    query {
      marketplaceBadge: file(
        relativePath: { eq: "image/addcraft/marketplace-partner-white.png" }
      ) {
        childImageSharp {
          fixed(width: 240, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <HeroWrapper>
      <TiltShape topOrBottom="bottom" color="#F2F2F2" />
      <Container>
        <Box {...row} className="header-row">
          <Box {...contentCol}>
            <Heading
              {...title}
              content="Our mission:"
            />
            <Text
              {...description}
              content="Creating the missing pieces for the tools you use daily."
            />
            <Box {...badgeWrapperWide} >
              <GImage
                fixed={Data.marketplaceBadge.childImageSharp.fixed}
                alt="Atlassian Marketplace Partner"
              />
            </Box>
          </Box>
          <Box {...imageCol} className="header-illustration">
            <HeroIllustration />
          </Box>
          <Box {...badgeWrapperSmall}>
              <GImage
                fixed={Data.marketplaceBadge.childImageSharp.fixed}
                alt="Atlassian Marketplace Partner"
              />
            </Box>
        </Box>
      </Container>
    </HeroWrapper>
  );
};

HeroSection.propTypes = {
  row: PropTypes.object,
  contentCol: PropTypes.object,
  imageCol: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  badgeWrapperWide: PropTypes.object,
  badgeWrapperSmall: PropTypes.object
};

HeroSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    pt: ['30px','30px','30px','100px','100px']
  },
  contentCol: {
    width: ['100%', '100%', '100%', '40%', '40%'],
    pt: '20px',
    flexGrow: 1,
    flexShrink: 1
  },
  imageCol: {
    width: ['100%', '100%', '100%', '60%', '60%'],
    pl: ['0','0','0','50px','50px'],
    pt: ['50px','50px','50px','0','0'],
    flexGrow: 1,
    flexShrink: 1
  },
  title: {
    fontSize: ['24px', '24px', '24px', '24px', '24px'],
    fontWeight: '600',
    color: '#fff',
    mb: ['10px', '10px', '10px', '10px', '10px'],
    textAlign: 'left',
  },
  description: {
    fontFamily: 'Montserrat Alternates',
    fontSize: ['36px', '36px', '36px', '36px', '36px'],
    color: '#fff',
    lineHeight: '1.4',
    mb: '0',
    textAlign: 'left',
  },
  badgeWrapperWide: {
    mt: '35px',
    display: ['none','none','none','block','block'],
  },
  badgeWrapperSmall: {
    mt: '35px',
    display: ['block','block','block','none','none'],
  },
};

export default HeroSection;
