import styled from 'styled-components';

const ContactSectionWrapper = styled.section`
  padding: 80px 0 80px 0;
  background: #FFF;

  @media (min-width: 990px) {
    & .right-column {
      border-left: 1px solid #DDD;
    }
  }
  
`;

export default ContactSectionWrapper;
