import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Cookies from 'universal-cookie';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

import Illustration from './cookie.illustration';

import Text from 'common/src/components/Text';

const cookies = new Cookies();

const PageWrapper = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);

  backdrop-filter: blur(5px);
`;

const DialogWrapper = styled.div`
  min-height: 300px;
  min-width: 300px;
  max-width: 500px;
  background: #FFF;
  padding: 180px 50px 50px 50px;
  border-radius: 16px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-height: 500px) {
    padding-top: 50px;
  }
`;

const PrimaryButton = styled.a`
  display: inline-block;
  position: relative;
  border-radius: 10px;
  background: #015788;

  padding: 12px 30px;

  font-weight: 600;
  font-family: "Montserrat Alternates";
  font-size: 16px;
  color: #FFF;

  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));

  cursor: pointer;
`;

const SecondaryButton = styled.a`
  color: #015788;
  display: block;
  padding-top: 20px;
  cursor: pointer;

  @media (min-width: 880px) {
    float: right;
    padding-top: 10px;
  }
`;

const IllustrationWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  top: -50px;

  @media (max-height: 500px) {
    display: none;
  }
`;

class CookieBanner extends Component {

  state = {
    needsShow: true,
    hasMounted: false
  }

  /**
   * We only render this component AFTER mounting to avoid rehydration problems
   * because of server side page generation (see https://www.joshwcomeau.com/react/the-perils-of-rehydration/)
   */
  componentDidMount () {
    this.setState({hasMounted: true});
  }

  render() {

    if (!this.state.hasMounted)
      return null;
    
    if (cookies.get("gdpr-consent") || !this.state.needsShow) {
      return null;
    } 

    var self = this;
    function handleAccept(e) {
      e.preventDefault();
      cookies.set("gdpr-consent", "true", {
        path: "/",
        maxAge: 365 * 24 * 60 * 60 // 1 year!
      });
      initializeAndTrack(window.location);
  
      self.setState({needsShow: false});
    };

    function handleReject(e) {
      self.setState({needsShow: false});
    };

    var DPLink = styled.a`
      display: block;
      color: #015788;
      margin-bottom: 40px;
      cursor: pointer;
    `;

    var bannerDOM = <PageWrapper>
      <DialogWrapper>
        <IllustrationWrapper>
          <Illustration />
        </IllustrationWrapper>

        <Text content="Hey there! We use cookies and Google Analytics to understand how our site is being used, analyze site traffic and for advertisement retargeting." />
        <DPLink target="_blank" href="https://help.addcraft.io/legal/latest/Data-Privacy-Policy-for-our-website.417497101.html">Learn more in our Data Privacy Policy.</DPLink>
        <PrimaryButton onClick={handleAccept}>Accept cookies</PrimaryButton>
        <SecondaryButton onClick={handleReject}>Reject cookies</SecondaryButton>
      </DialogWrapper>
    </PageWrapper>;

    return (
      bannerDOM
    );
  }
};

CookieBanner.propTypes = {
  content: PropTypes.string
};

export default CookieBanner;
