import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import 'rc-tabs/assets/index.css';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import TiltShape from '../TiltShape';

import Tile from '../Tile';

import SectionWrapper from './products.style';

const ProductsSection = ({
  secTitleWrapper,
  secHeading,
  row
}) => {

  const Data = useStaticQuery(graphql`
    query {
      presenterImage: file(
        relativePath: { eq: "image/addcraft/tileheader-presenter.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stayTunedImage: file(
        relativePath: { eq: "image/addcraft/tileheader-staytuned.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <SectionWrapper id="products">
      <TiltShape />
      <TiltShape topOrBottom="bottom" />

      <Container className="products-container">
        <Box {...secTitleWrapper}>
          <Heading {...secHeading} content="Our products" />
        </Box>
        <Box {...row}>
          <Tile
            imageData={Data.presenterImage}
            title="Slide Presenter for Confluence"
            details="Turn Confluence pages into beautiful slideshows with one click. Present directly in your browser."
            href="https://marketplace.atlassian.com/apps/1213922/presenter-for-confluence"
          />
          <Tile
            imageData={Data.stayTunedImage}
            title="Stay tuned..."
            details="New and exciting products are on our roadmap. Stay tuned."
          />
        </Box>
      </Container>
    </SectionWrapper>
  );
};

ProductsSection.propTypes = {
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
};

ProductsSection.defaultProps = {
  secTitleWrapper: {
    mb: ['60px', '80px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap'
  },
  secHeading: {
    textAlign: 'center',
    fontSize: '36px',
    fontWeight: '600',
    color: '#005688',
    mb: '0'
  },
};

export default ProductsSection;
